@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Medium.woff2') format('woff2'),
    url('../assets/fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../assets/fonts/Gotham-Book.woff2') format('woff2'),
    url('../assets/fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-MediumItalic.woff2') format('woff2'),
    url('../assets/fonts/Gotham-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
